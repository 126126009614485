<template>
<div>
<listLayout
    ref="ListLayout"
    v-show="thead[theadKey].length && form.tab === 'post_count'"
    v-if="form.tab === 'post_count'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <preference-filter
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></preference-filter>
    </template>
  </listLayout>
  <listLayout
    ref="ListLayout"
    v-show="thead[theadKey].length && form.tab === 'post_percent'"
    v-if="form.tab === 'post_percent'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <preference-filter
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></preference-filter>
    </template>
  </listLayout>
  <listLayout
    ref="ListLayout"
    v-show="thead[theadKey].length && form.tab === 'comment_count'"
    v-if="form.tab === 'comment_count'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <preference-filter
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></preference-filter>
    </template>
  </listLayout>
  <listLayout
    ref="ListLayout"
    v-if="thead[theadKey].length && form.tab === 'comment_percent'"
    :tabArr="tabArr"
    :on-fetch="getList"
    :filter-form="filterForm"
    :thead="thead[theadKey]"
    @handletab="handletab"
  >
    <template #filter>
      <preference-filter
        :page="pageData.current_page"
        :filterChange="onFilter"
        :exportData="filterForm"
      ></preference-filter>
    </template>
  </listLayout>
</div>

</template>
<script>
import preferenceFilter from "../../components/group/preferenceFilter";
import ListLayout from "@/base/layout/ListLayout";
import { List } from "../../api/group/preference";
export default {
  data() {
    return {
      // 加载
      loading: false,
      tbody: [],
      // tab配置
      tabArr: [
        { label: "发帖类型次数", name: "post_count" },
        { label: "发帖类型占比", name: "post_percent" },
        { label: "评论类型次数", name: "comment_count" },
        { label: "评论类型占比", name: "comment_percent" },
      ],
      thead: {},
      // 页码
      pageData: {
        current_page: 1
      },
      // 筛选对象
      filterForm: {
        id: this.$route.params.id,
        tab: "post_count",
        nickname: "",
        title_name: "",
        start_time: -1,
        position_id: -1, // 职务id
        // positionIds: [], // 会内职务
        end_time: -1,
        page_size: 20,
      },
      // form
      form: {
        tab: 'post_count'
      },
    };
  },

  methods: {
    getList(data) {
      return List(data)
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.ListLayout.getList();
    },
    // 切换tab 更改is_desc更改参数
    handletab(tab) {
      if (tab === 'post_count') {
        return this.filterForm.order_by = 'post_interact_count'
      } else if (tab === 'post_percent') {
        return this.filterForm.order_by = 'post_interact_percent'
      } else if (tab === 'comment_count') {
        return this.filterForm.order_by = 'comment_interact_count'
      } else {
        return this.filterForm.order_by = 'comment_interact_percent'
      }
    }
  },
  created() {
    let thead = {
      // 发帖类型次数
      post_count: [
        { label: "序号", type:"index", minWidth: 200},
        { label: "头像", prop:"avatar", minWidth: 200},
        { label: "用户名称", prop:"nickname", minWidth: 200},
        { label: "会内职务", prop:"title_name", minWidth: 200},
        { label: "纯文字（包含表情）", prop:"text_post_count", minWidth: 200},
        { label: "纯图片", prop:"image_post_count", minWidth: 200},
        { label: "图片+文字（包含表情）", prop:"text_image_post_count", minWidth: 200 },
        { label: "纯视频", prop:"video_post_count", minWidth: 200},
        { label: "视频+文字（包含表情）", prop:"text_video_post_count", minWidth: 200},
        { label: "合计", sortable: true,  prop:"post_interact_count", sort_orders: ['ascending', 'descending'], minWidth: 200},
      ],
      // 发帖类型占比
      post_percent: [
        { label: "序号", type:"index", minWidth: 200  },
        { label: "头像", prop:"avatar", minWidth: 200  },
        { label: "用户名称", prop:"nickname", minWidth: 200  },
        { label: "会内职务", prop:"title_name", minWidth: 200  },
        { label: "纯文字（包含表情）", prop:"text_post_percent", minWidth: 200  },
        { label: "纯图片", prop:"image_post_percent", minWidth: 200  },
        { label: "图片+文字（包含表情）", prop:"text_image_post_percent", minWidth: 200  },
        { label: "纯视频", prop:"video_post_percent", minWidth: 200  },
        { label: "视频+文字（包含表情）", prop:"text_video_post_percent", minWidth: 200  },
        { label: "合计", sortable: true,  prop:"post_interact_percent", sort_orders: ['ascending', 'descending'], minWidth: 200  },
      ],     
      // 评论类型次数
      comment_count: [
        { label: "序号", type:"index", minWidth: 200 },
        { label: "头像", prop:"avatar", minWidth: 200 },
        { label: "用户名称", prop:"nickname", minWidth: 200 },
        { label: "会内职务", prop:"title_name", minWidth: 200 },
        { label: "纯文字", prop:"text_comment_count", minWidth: 200 },
        { label: "文字+表情", prop:"text_emoji_comment_count", minWidth: 200 },
        { label: "纯表情", prop:"emoji_comment_count", minWidth: 200 },
        { label: "合计", sortable: true,  prop:"comment_interact_count", sort_orders: ['ascending', 'descending'], minWidth: 200 },
      ],      
      // 评论类型占比
      comment_percent: [
        { label: "序号", type:"index",minWidth: 200 },
        { label: "头像", prop:"avatar", minWidth: 200 },
        { label: "用户名称", prop:"nickname", minWidth: 200 },
        { label: "会内职务", prop:"title_name", minWidth: 200},
        { label: "纯文字", prop:"text_comment_percent",minWidth: 200 },
        { label: "文字+表情", prop:"text_emoji_comment_percent", minWidth: 200 },
        { label: "纯表情", prop:"emoji_comment_percent", minWidth: 200 },
        { label: "合计", sortable: true,  prop:"comment_interact_percent", sort_orders: ['ascending', 'descending'], minWidth: 200 },
      ],
    };
    this.thead = thead;
  },
  watch: {
  },
  computed: {
    // 获取tab
    theadKey() {
      return this.form.tab = this.filterForm.tab;
    },
  },
  components: {
    preferenceFilter,
    ListLayout,
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin: 20px 0px;
  .el-tabs {
    .el-tab-pane {
      margin-top: 20px;
    }
  }
}
</style>
