// 成员活跃度接口
import api from "@/base/utils/request";
import download from "@/base/utils/download"

// 获取列表
export const List = data => {
    return api({
      url: "/admin/circle/GroupInteract/interactData",
      method: "post",
      data
    });
  };

// 下载导出
export const preferenceExport = data => {
  return download({
    url: "/admin/circle/GroupInteract/interactDataExport",
    method: "post",
    data,
    download: true
  });
};

// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};
  